import isEqual from 'lodash.isequal';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { RuleSetEditorStateService } from '../core/state/ruleset-editor-state.service';
import * as StateModel from '../core/models/state.model';
import { combineLatest, Observable, pipe, UnaryFunction } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { DocParserService } from '../core/doc-parser/doc-parser.service';
import { UtilsService } from '../core/utils/utils.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class RuleSetEditorFacadeService {
  constructor(
    private loadingService: LoadingService,
    private api: ApiService,
    private state: RuleSetEditorStateService,
    private docParser: DocParserService,
    private utils: UtilsService
  ) {}

  // State getters

  getRulesLoading(): Observable<boolean> {
    return this.state.rulesLoading;
  }

  getRuleSetOptions(): Observable<StateModel.IRuleSetOption[]> {
    return this.state.ruleSetOptions.pipe(
      map((docs: StateModel.IRuleSetOption[]) =>
        docs.map(({ _id, name }) => {
          return { _id, name };
        })
      )
    );
  }

  /**
   * Combines current state of RuleSet _id and name properties into one RuleSet option object
   * @returns {Observable} Observable resolving to the currently selected RuleSet option object
   */
  getSelectedRuleSetOption(): Observable<StateModel.IRuleSetOption | null> {
    return combineLatest([
      this.state.currentRuleSetId,
      this.state.currentRuleSetProperties,
    ]).pipe(
      map(([_id, properties]: any) => {
        if (!_id || !properties) return null;
        return { _id, name: properties.name };
      })
    );
  }

  getAvailableRules(): Observable<StateModel.IDocOption[]> {
    return this.state.currentAvailableRules;
  }

  getRuleSetTypes(): Observable<string[]> {
    return this.state.ruleSetTypes;
  }

  getIsNew(): Observable<boolean> {
    return this.state.isNew;
  }

  getHasUnsavedChanges(): Observable<boolean> {
    return this.state.hasUnsavedChanges;
  }

  getCurrentRuleSetId(): Observable<string> {
    return this.state.currentRuleSetId;
  }

  getCurrentRuleSetProperties(): Observable<StateModel.TCurrentRuleSetPropertiesState> {
    return this.state.currentRuleSetProperties;
  }

  getCurrentRuleSetRulesRef(): Observable<string[]> {
    return this.state.currentRuleSetRulesRef;
  }

  getValidationStatus(): Observable<StateModel.TValidationStatus | ''> {
    return this.state.validationResult.pipe(
      map((result: StateModel.TValidationResultState) =>
        result ? result.result : ''
      )
    );
  }

  getValidationResult(): Observable<StateModel.TValidationResultState> {
    return this.state.validationResult;
  }

  getValidationIsStale(): Observable<boolean> {
    return this.state.validationIsStale;
  }

  getCurrentRuleSetMetadata(): Observable<StateModel.TCurrentDocMetadataState> {
    return this.state.currentRuleSetMetadata;
  }

  // Utils

  /**
   * Compares current value of ruleSetContent form group to current value of state and updates hasUnsavedChanges state property accordingly
   * @param formValue Current value of the ruleSetContent form group
   */
  checkForUnsavedChanges(formValue: any): void {
    const { rulesRef, ...properties } = formValue;
    const stateRulesRef = this.state.currentRuleSetRulesRef.getValue();
    const stateProperties = this.state.currentRuleSetProperties.getValue();

    if (stateProperties && !isEqual(properties, stateProperties)) {
      this.state.setHasUnsavedChanges(true);
    } else if (stateRulesRef && !isEqual(rulesRef, stateRulesRef)) {
      this.state.setHasUnsavedChanges(true);
    } else this.state.setHasUnsavedChanges(false);
  }

  /**
   * Set all state properties pertaining to current RuleSet to copies of themselves
   */
  refreshRuleSet(...toReset: string[]): void {
    const resetAll = toReset.length === 0;

    if (resetAll || toReset.includes('id'))
      this.state.setCurrentRuleSetId(this.state.currentRuleSetId.getValue());
    if (resetAll || toReset.includes('properties'))
      this.state.setCurrentRuleSetProperties(
        this.state.currentRuleSetProperties.getValue()
      );
    if (resetAll || toReset.includes('rules'))
      this.state.setCurrentRuleSetRulesRef(
        this.state.currentRuleSetRulesRef.getValue()
      );
    if (resetAll || toReset.includes('metadata'))
      this.state.setCurrentRuleSetMetadata(
        this.state.currentRuleSetMetadata.getValue()
      );
    if (resetAll || toReset.includes('unsavedChanges'))
      this.state.setHasUnsavedChanges(false);
  }

  /**
   * Reset all state properties pertaining to current RuleSet to default values
   */
  clearRuleSet(): void {
    this.state.setCurrentRuleSetId('');
    this.state.setCurrentRuleSetProperties(null);
    this.state.setCurrentRuleSetRulesRef([]);
    this.state.setCurrentRuleSetMetadata(null);
    this.state.setIsNew(false);
    this.state.setHasUnsavedChanges(false);
  }

  /**
   * Reset validationResult state to null
   */
  clearValidationResults(): void {
    this.state.setValidationResult(null);
  }

  /**
   * Clears RuleSet state, initializes state to blank RuleSet values, and loads available rules
   * @returns Observable resolving to the updated list of available rules
   */
  startNewRuleSet(): Observable<StateModel.IFullDoc[]> {
    this.clearRuleSet();
    this.state.setIsNew(true);
    this.state.setCurrentRuleSetId('-1');
    this.state.setCurrentRuleSetProperties({
      name: '',
      type: '',
      year: '',
      description: '',
    });
    this.state.setCurrentRuleSetRulesRef([]);
    this.state.setCurrentRuleSetMetadata(null);
    return this.loadAvailableRules();
  }

  /**
   * Replace RuleSet id/metadata state with "new" state and change rule name to indicate cloning
   */
  cloneCurrentRuleSet(): void {
    this.state.setCurrentRuleSetId('-1');
    this.state.setCurrentRuleSetMetadata(null);

    const props = {
      ...this.state.currentRuleSetProperties.getValue(),
    } as StateModel.IRuleSetProperties;
    props.name = this._makeCloneName(props.name as string);
    this.state.setCurrentRuleSetProperties(props);

    this.state.setIsNew(true);
    this.state.setHasUnsavedChanges(true);
  }

  // Data-loading API calls

  /**
   * Fetch all RuleSet documents from API and load them into state
   * @returns {Observable} Observable resolving to a list of all RuleSet options
   */
  loadRuleSetOptions(): Observable<StateModel.IRuleSetOption[]> {
    return this.api.getDocumentList('rule_set').pipe(
      map((options: StateModel.IRuleSetOption[]) =>
        options.map((o: StateModel.IRuleSetOption) => {
          o.name = this.utils.coerceToString(o.name);
          return o;
        })
      ),
      tap((options: StateModel.IRuleSetOption[]) => {
        this.state.setRuleSetOptions(options);
      }),
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Fetch all rule documents from API and load them into state, setting rulesLoading state accordingly
   * @returns {Observable} Observable resolving to a list of all rule documents
   */
  loadAvailableRules(): Observable<StateModel.IFullDoc[]> {
    this.state.setRulesLoading(true);
    return this.api.getDocumentList('rule').pipe(
      tap((rules) => {
        this.state.setCurrentAvailableRules(rules);
        this.state.setRulesLoading(false);
      }),
      catchError((err: any) => {
        this.state.setRulesLoading(false);
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Fetch all RuleSet type documents from API and load them into state
   * @returns {Observable} Observable resolving to a list of RuleSet type document names (string)
   */
  loadRuleSetTypes(): Observable<string[]> {
    return this.api.getDocumentList('rule_set_type').pipe(
      map((types) => types.map((t: StateModel.IFullDoc) => t.name)),
      tap((typeNames) => {
        this.state.setRuleSetTypes(typeNames);
      }),
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Fetch specified rule_set document from API, parse it, and load it into state
   * @param id the string id of a rule_set document
   * @returns {Observable} Observable resolving to a rule_set document
   */
  loadRuleSet(id: string): Observable<StateModel.IFullRuleSet> {
    return this.api.getDocument('rule_set', id).pipe(
      this._clearRuleSetPipe(),
      map((ruleSet: StateModel.IFullRuleSet) => {
        ruleSet.name = this.utils.coerceToString(ruleSet.name);
        return ruleSet;
      }),
      tap((ruleSet: StateModel.IFullRuleSet) => {
        const { _id, properties, rulesRef, metadata } =
          this.docParser.parseFullRuleSet(ruleSet) as StateModel.IParsedRuleSet;
        this.state.setCurrentRuleSetId(_id);
        this.state.setCurrentRuleSetProperties(properties);
        this.state.setCurrentRuleSetRulesRef(rulesRef);
        this.state.setCurrentRuleSetMetadata(metadata);
      }),
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  // CRUD API calls requiring loading state

  /**
   * Load the specified RuleSet, load/reload all available rules, and toggle loading state accordingly
   * @param id the string id of a RuleSet document
   * @returns {Observable} Observable resolving to an updated list of available rules
   */
  setCurrentRuleSet(id: string): Observable<any> {
    this.loadingService.startLoading();
    return this.loadRuleSet(id).pipe(
      tap(() => {
        this.clearValidationResults();
      }),
      this._stopLoadingPipe(),
      concatMap(() => this.loadAvailableRules()),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  /**
   * Sends current state of RuleSet properties and included rule IDs to API, retrieves the newly created RuleSet, and updates state
   * @param properties - RuleSet properties object from form
   * @param rulesRef - Array of rule id strings
   * @returns Observable resolving to updated list of RuleSet options
   */
  createNewRuleSet(
    properties: StateModel.IRuleSetProperties,
    rulesRef: string[]
  ): Observable<any> {
    this.loadingService.startLoading();

    const ruleSet = { ...properties, rules_ref: rulesRef };

    return this.api.createDocument('rule_set', ruleSet).pipe(
      concatMap(({ doc_id }) => this.loadRuleSet(doc_id)),
      concatMap(() => this.loadRuleSetOptions()),
      this._staleValidationPipe(true),
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  /**
   * Compiles the current state of the working RuleSet, sends to API, and updates current RuleSet, available rule, and RuleSet options state
   * @param properties Object containing name, type, year, and description of RuleSet
   * @param rulesRef Array of string ids for rule documents included in RuleSet
   * @returns {Observable} Observable resolving to updated list of RuleSet options
   */
  updateCurrentRuleSet(
    properties: StateModel.IRuleSetProperties,
    rulesRef: string[]
  ): Observable<any> {
    this.loadingService.startLoading();

    const document = this.docParser.compileFullRuleSet({
      properties,
      rulesRef,
    }) as StateModel.IFullRuleSet;
    const formData = this.utils.makeFormData({ document });

    return this.api.updateDocument('rule_set', formData).pipe(
      concatMap(({ doc_id }) => this.loadRuleSet(doc_id)),
      concatMap(() => this.loadRuleSetOptions()),
      this._staleValidationPipe(true),
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  /**
   * Sends the id of document to be deleted to the API and clears ruleset state upon success
   * @returns {Observable} resolving to updated list of ruleset options
   */
  deleteCurrentRuleSet(): Observable<any> {
    this.loadingService.startLoading();

    const rulesetId = this.state.currentRuleSetId.getValue();

    return this.api.deleteDocument('rule_set', rulesetId).pipe(
      concatMap(() => this.loadRuleSetOptions()),
      tap(() => {
        this.clearRuleSet();
      }),
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  /**
   * Fetch one rule document from the API
   * @param id string id of rule to retrieve from API
   * @returns Observable resolving to the selected rule document
   */
  getRuleById(id: string): Observable<any> {
    this.loadingService.startLoading();
    return this.api.getDocument('rule', id).pipe(
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  /**
   * Fetch one ruleSet package document from the API
   * @param id string id of ruleSet package to retrieve from API
   * @returns Observable resolving to the selected ruleSet package document
   */
  getRuleSetPackageById(id: string): Observable<any> {
    this.loadingService.startLoading();
    return this.api.getDocument('rule_set_package', id).pipe(
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    )
  }

  /**
   * Call API method to validate current ruleSet by ID
   * @returns {Observable} resolving to the validation result
   */
  validateCurrentRuleSet(): Observable<StateModel.IValidationResult> {
    const id = this.state.currentRuleSetId.getValue();
    return this.api.validateRuleSet(id).pipe(
      this._deDupeValidationErrorsPipe(),
      tap((result: StateModel.IValidationResult) => {
        this.state.setValidationResult(result);
      }),
      this._staleValidationPipe(false),
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Call API method to publish ruleSet
   * @returns {Observable}
   */
  publishCurrentRuleSet(): Observable<any> {
    const doc_id = this.state.currentRuleSetId.getValue();
    const formData = this.utils.makeFormData({ doc_id });
    return this.api.generateRuleSetPackage(formData).pipe(
      tap((result: any) => {
        if (result.errors.length) {
          const { export_package_id, ...validationResult } = result;
          this.state.setValidationResult(validationResult);

          const numErr = result.errors.length;
          const verb = numErr === 1 ? 'was' : 'were';
          const s = numErr === 1 ? '' : 's';

          throw new Error(
            `There ${verb} ${numErr} error${s} found during RuleSet package generation.  This is likely because another user made changes to the RuleSet before the publish attempt. Refresh the page to see the current state.`
          );
        }
      }),
      concatMap((result: any) => {
        const { export_package_id } = result;
        return this.api.publishRuleSetPackage(doc_id, export_package_id);
      }),
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  getRuleSetPublishedStatus(): Observable<any> {
    const id = this.state.currentRuleSetId.getValue();
    return this.api.getPublishedStatus(id).pipe(
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  // PRIVATE

  // Utils

  /**
   * Creates a "clone" version of a RuleSet document name
   * @param name - string name of a RuleSet document
   * @returns string name of a cloned RuleSet document
   */
  private _makeCloneName(name: string): string {
    return `${name} Clone`;
  }

  // Pipeable operators

  /**
   * Sets isLoading state to false
   * @returns {UnaryFunction}
   */
  private _stopLoadingPipe = () => {
    return pipe(
      tap((value: any) => {
        this.loadingService.stopLoading();
        return value;
      })
    );
  };

  /**
   * Run clearRuleset function, but as a pipeable operator
   * @returns {UnaryFunction}
   */
  private _clearRuleSetPipe = () => {
    return pipe(
      tap((value: any) => {
        this.clearRuleSet();
        return value;
      })
    );
  };

  /**
   * Remove duplicate RuleSet validation errors
   * @returns {UnaryFunction}
   */
  private _deDupeValidationErrorsPipe = () => {
    return pipe(
      map((validationResult: StateModel.IValidationResult) => {
        const unique = new Set();

        validationResult.errors = validationResult.errors.filter(
          (err: StateModel.IValidationError) => {
            const errStr = JSON.stringify(err);
            if (unique.has(errStr)) return false;
            unique.add(errStr);
            return true;
          }
        );

        return validationResult;
      })
    );
  };

  /**
   * Set validationIsStale state as a pipeable operator
   * @param isStale boolean
   * @returns {UnaryFunction}
   */
  private _staleValidationPipe = (isStale: boolean) => {
    return pipe(
      tap((value: any) => {
        this.state.setValidationIsStale(isStale);
        return value;
      })
    );
  };
}
