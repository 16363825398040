<p-blockUI [blocked]="true" [autoZIndex]="false"></p-blockUI>
<div
  id="blocking-spinner-modal"
  class="
    blocking-spinner-modal
    flex
    justify-content-center
    align-items-center
  "
>
  <h3 class="blocking-spinner-modal-header">{{ message }}</h3>
</div>
<p-progressSpinner
  class="blocking-spinner"
  strokeWidth="1"
></p-progressSpinner>
