import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TValidCollectionName } from '../models/api.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private BASE_URL: string = '/api';
  private PUBLISHRULESET_API_URL: string = environment.PUBLISHRULESET_API_URL;
  private PUBLISHRULESET_API_KEY: string = environment.PUBLISHRULESET_API_KEY;

  constructor(private http: HttpClient) {}

  getCollectionList(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/db_status`);
  }

  getDocumentList(collection: TValidCollectionName): Observable<any> {
    return this.http.get(`${this.BASE_URL}/${collection}`);
  }

  getDocument(collection: TValidCollectionName, id: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/${collection}?doc_id=${id}`);
  }

  createDocument(collection: TValidCollectionName, doc: any): Observable<any> {
    const json = JSON.stringify(doc);
    return this.http.post(
      `${this.BASE_URL}/${collection}?document=${json}`,
      null
    );
  }

  updateDocument(
    collection: TValidCollectionName,
    formData: FormData
  ): Observable<any> {
    const doc = JSON.parse(formData.get('document') as string);
    if (!doc.hasOwnProperty('_id')) {
      return throwError(
        new Error(
          'To update an existing document you must provide an "_id" property'
        )
      );
    }
    return this.http.post(`${this.BASE_URL}/${collection}`, formData);
  }

  deleteDocument(
    collection: TValidCollectionName,
    docId: string
  ): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/${collection}?doc_id=${docId}`);
  }

  validateRuleSet(ruleSetId: string): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/validate_rule_set?doc_id=${ruleSetId}`
    );
  }

  generateRuleSetPackage(formData: FormData): Observable<any> {
    return this.http.post(`${this.BASE_URL}/publish_rule_set`, formData);
  }

  publishRuleSetPackage(rule_set_id: string, rule_set_package_id: string): Observable<any> {
    const httpOptions = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-functions-key': this.PUBLISHRULESET_API_KEY,
      }),
    };

    return this.http.post(
      `${this.PUBLISHRULESET_API_URL}/api/publish_rule_set_package`,
      { rule_set_id, rule_set_package_id },
      httpOptions
    );
  }

  getPublishedStatus(ruleSetId: string): Observable<any> {
    const criteria = {
      activity: 'publish',
      context: `{"id": "${ruleSetId}"}`,
    };
    return this.http.get(
      `${this.BASE_URL}/activity_log?criteria=${JSON.stringify(criteria)}`
    );
  }
}
