import { Component, Input } from '@angular/core';

@Component({
  selector: 'rct-blocking-spinner',
  templateUrl: './blocking-spinner.component.html',
  styleUrls: ['./blocking-spinner.component.scss'],
})
export class BlockingSpinnerComponent {
  @Input() message: string;
}
