import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { ToolbarModule } from 'primeng/toolbar';
import { DynamicDialogModule, DialogService } from 'primeng/dynamicdialog';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { PickListModule } from 'primeng/picklist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AppRoutingModule } from './app-routing.module';
import { LoadingModule } from './loading/loading.module';
import { AppComponent } from './app.component';
import { JsonEditorComponent } from './components/json-editor/json-editor.component';
import { DocumentEditorComponent } from './components/document-editor/document-editor.component';
import { TemplateSelectorComponent } from './components/template-selector/template-selector.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { RuleSetEditorComponent } from './components/ruleset-editor/ruleset-editor.component';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { EditorToolbarComponent } from './components/editor-toolbar/editor-toolbar.component';
import { ToolbarBreakpointsDirective } from './components/editor-toolbar/directives/toolbar-breakpoints.directive';
import { ValidationErrorDisplayComponent } from './components/validation-error-display/validation-error-display.component';
import { BlockingSpinnerComponent } from './components/blocking-spinner/blocking-spinner.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    JsonEditorComponent,
    DocumentEditorComponent,
    TemplateSelectorComponent,
    CopyButtonComponent,
    RuleSetEditorComponent,
    JsonViewerComponent,
    EditorToolbarComponent,
    ToolbarBreakpointsDirective,
    ValidationErrorDisplayComponent,
    BlockingSpinnerComponent,
    AuditLogComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    SplitButtonModule,
    DropdownModule,
    AutoCompleteModule,
    DialogModule,
    CardModule,
    NgJsonEditorModule,
    ToastModule,
    DividerModule,
    ToolbarModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    ListboxModule,
    MenuModule,
    TabViewModule,
    InputTextModule,
    PickListModule,
    NgxJsonViewerModule,
    LoadingModule,
    OverlayPanelModule,
    TableModule,
    BlockUIModule,
    ProgressSpinnerModule,
    PanelModule,
    TooltipModule,
  ],
  providers: [ConfirmationService, MessageService, DialogService],
  bootstrap: [AppComponent],
})
export class AppModule {}
