import { Injectable } from '@angular/core';
import { FormatterService } from '../formatter/formatter.service';
import * as StateModel from '../models/state.model';

interface IValidationErrorMessage {
  description: string;
  solution: string;
}

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorMessageService {
  constructor(private formatter: FormatterService) {}

  generateErrorMessage(
    error: StateModel.IValidationError
  ): IValidationErrorMessage {
    const message = {
      description: error.description,
      solution: 'No suggested solution.',
    };

    switch (true) {
      case /^1001/.test(error.code): // any year errors
        message.solution = `Select a valid year from the dropdown and save.`;
        break;
      case /^1002/.test(error.code): // any name errors
        message.solution = `Enter a RuleSet name that is 30 characters or less and save.`;
        break;
      case /^1003/.test(error.code): // any type errors
        message.description = this.formatter.formatRuleSet(error.description);
        message.solution = `Select a valid type from the dropdown and save.`;
        break;
      case /^1004/.test(error.code): // any description errors
        message.solution = `Enter a description for the RuleSet and save.`;
        break;
      case /100701/.test(error.code): // rules ref is not defined
        message.solution = `Make an update to any RuleSet field and save to update property name.`;
        break;
      case /100702|100703/.test(error.code): // rules ref empty/at least one rule must be referenced
        message.solution = `Ensure at least one rule is added to the RuleSet.`;
        break;
      case /200201/.test(error.code): // rule not found
        message.solution = `Ensure rule "${error.name}" exists or remove it from the RuleSet.`;
        break;
      case /200202/.test(error.code): // rule has no bindings property
        message.solution = `Ensure rule with ID "${error.rule_id}" contains a "bindings" property.`;
        break;
      case /200301/.test(error.code): // field not found
        message.description = `Field "${error.name}" was not found.`;
        message.solution = `Ensure field "${error.name}" exists or remove it from all rules.`;
        break;
      case /200401/.test(error.code): // menu not found
        message.description = `Menu "${error.name}" was not found.`;
        message.solution = `Ensure menu "${error.name}" exists or remove it from all rules.`;
        break;
      case /500201/.test(error.code): // doc_id param not given in request to package handler
        message.solution = `Ask a front end developer to fix the request object with the correct parameter.`;
        break;
      case /600211/.test(error.code): // RuleSet package rule doesn't contain valid name
        message.solution = `Ensure the exported rule "${error.name}" has a valid name property.`;
        break;
      case /600221/.test(error.code): // RuleSet package rule has invalid format
        message.solution = `Ensure the exported rule "${error.name}" is formatted correctly.`;
        break;
      case /600311/.test(error.code): // RuleSet package field doesn't contain valid name
        message.solution = `Ensure the exported field "${error.name}" has a valid name property.`;
        break;
      case /600321/.test(error.code): // Ruleset package field has invalid format
        message.solution = `Ensure the exported field "${error.name}" is formatted correctly.`;
        break;
      case /600411/.test(error.code): // RuleSet package menu doesn't contain valid name
        message.solution = `Ensure the exported menu "${error.name}" has a valid name property.`;
        break;
      case /600421/.test(error.code): // Ruleset package menu has invalid format
        message.solution = `Ensure the exported menu "${error.name}" is formatted correctly.`;
    }

    return message;
  }
}
